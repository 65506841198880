<template lang="pug">
  .ds-ts-list(v-infinite-scroll="onLoadMore" infinite-scroll-disabled="disabledLoadingMore" infinite-scroll-distance="50")
    v-data-table(v-show="false" :headers="headers")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :list="list"
      itemKey="id"
      hide-default-footer
      :server-items-length="list.length"
      :single-select="false"
      :loading="loading"
      :show-select="false"
      :mobile-breakpoint='NaN'
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      hide-default-header
      class="app-table-new v-data-table theme--light"
    )
      template(v-slot:item="{item, select, isSelected, index}")
        item(
          :item="item"
          :isSelected="isSelected"
          :select="select"
          :index="index"
          :key="item.id"
          :activeItems="activeItems"
          :pagination="pagination"
          :primaryPagination="primaryPagination"
          @click:link="clickItemLink"
          @needToUpdate="$emit('needToUpdate')"
        )
</template>

<script>
import ROUTE_NAMES from '@/app/admin/router/router-const'
import InfinityScroll from '@/components/global/infinityScroll.vue'
import SortModel from '../../core/drivingSchool-sortModel'

export default {
  name: 'DrivingSchoolPageTransactionsList',

  props: {
    list: Array,
    headers: Array,
    loading: Boolean,
    pagination: Object,
    primaryPagination: Object
  },

  data: () => ({
    activeItems: [],
    sortModel: null
  }),

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false
    },
  },

  created() {
    this.initSortModel()
  },

  methods: {
    initSortModel() {
      this.sortModel = new SortModel(this.$route.query)
    },

    onLoadMore() {
      if (!this.pagination) return
      this.$emit('loadMore')
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if (data.ordering.length) ordering = data.ordering
      this.$emit('change:sorting', ordering)
    },

    async clickItemLink(item) {
      await this.$router.replace({
        name: ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS,
        query: {
          page: 1,
          transaction: item?.id
        }
      })
    }
  },

  components: {
    infinityScroll: InfinityScroll,
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./SchoolTransactionsPageListItem.vue')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/tableNew';

.app-table-new .v-data-table tbody tr td:first-child {
  width: 32px;
  height: 32px;
}

.ds-ts-list {
  min-height: 100vh;
}

.task-app-table {
  display: table;
  width: 100%;
}
</style>
